var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dataLoaded ? _c('table', {
    key: _vm.updateKey,
    staticClass: "table-wrapper",
    attrs: {
      "id": "table-body"
    }
  }, [_c('tr', {
    staticClass: "table-head",
    staticStyle: {
      "border-bottom": "1px solid #e6e8ec !important"
    },
    on: {
      "click": function ($event) {
        return _vm.columnAction('closeAll');
      }
    }
  }, _vm._l(_vm.sortedFields, function (field, index) {
    return _c('th', {
      key: field.fieldValue,
      staticClass: "table-head-item",
      on: {
        "click": function ($event) {
          return _vm.columnAction(index, field);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap"
      }
    }, [_c('p', {
      staticClass: "column-label",
      class: {
        'bold-weight': _vm.sortCounters[index][field.jsonValue] !== 1,
        'normal-weight': _vm.sortCounters[index][field.jsonValue] === 1
      }
    }, [_vm._v(" " + _vm._s(field.fieldValue) + " ")]), field.jsonValue !== 'files' && field.jsonValue !== 'floor' &&
    //field.jsonValue !== 'status' &&
    field.jsonValue !== 'unitSize' && field.jsonValue !== 'client' && field.jsonValue !== 'description' && field.jsonValue !== 'price' ? _c('img', {
      class: {
        up: _vm.sortCounters[index][field.jsonValue] === 2,
        down: _vm.sortCounters[index][field.jsonValue] === 3,
        hidden: _vm.sortCounters[index][field.jsonValue] === 1
      },
      staticStyle: {
        "width": "16px",
        "height": "16px"
      },
      attrs: {
        "src": require("../../assets/images/direction=up.svg"),
        "alt": ""
      }
    }) : _vm._e()])], 1);
  }), 0), _c('div'), _vm._l(_vm.sortedDataSet, function (object, intex) {
    return _c('tr', {
      key: object._id,
      class: {
        'table-body-row': !object.recentlyAdded,
        'table-body-row recently-added': object.recentlyAdded
      }
    }, _vm._l(_vm.sortedFields, function (field, index) {
      return _c('td', {
        key: field.jsonValue,
        staticClass: "table-body-item",
        style: field.style,
        on: {
          "click": function (event) {
            return _vm.columnClick(index, field, object, event);
          }
        }
      }, [field.jsonValue === 'firstName' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(_vm._s(object.firstName))])])], 1) : field.jsonValue === 'lastName' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(object.lastName) + " ")])])], 1) : field.jsonValue === 'paidBrokerageFee' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.paidBrokerageFee, "€")) + " ")])])], 1) : field.jsonValue === 'unpaidBrokerageFee' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.unpaidBrokerageFee, "€")) + " ")])])], 1) : field.jsonValue === 'totalBrokerageFee' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.totalBrokerageFee, "€")) + " ")])])], 1) : field.jsonValue === 'paid' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.paid, "")) + " ")])])], 1) : field.jsonValue === 'unpaid' && _vm.invoicesView ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.unpaid, "")) + " ")])])], 1) : field.jsonValue === 'sum' ? _c('div', [_c('v-row', [!object.totalSum ? _c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.sum, "")) + " ")]) : _c('div', {
        staticStyle: {
          "display": "flex",
          "gap": "4px",
          "align-items": "flex-end",
          "white-space": "nowrap"
        }
      }, [_c('p', {
        staticClass: "content-normal"
      }, [_vm._v(_vm._s(_vm.$nMapper.price(object.sum, "")) + " / ")]), _c('p', {
        staticClass: "content-small",
        staticStyle: {
          "line-height": "unset"
        }
      }, [_vm._v(_vm._s(_vm.$nMapper.price(object.totalSum, "")))])])])], 1) : field.jsonValue === 'sumVAT' ? _c('div', [_c('v-row', [!object.totalSumVAT ? _c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.sumVAT, "")) + " ")]) : _c('div', {
        staticStyle: {
          "display": "flex",
          "gap": "4px",
          "align-items": "flex-end",
          "white-space": "nowrap"
        }
      }, [_c('p', {
        staticClass: "content-normal"
      }, [_vm._v(_vm._s(_vm.$nMapper.price(object.sumVAT, "")) + " / ")]), _c('p', {
        staticClass: "content-small",
        staticStyle: {
          "line-height": "unset"
        }
      }, [_vm._v(_vm._s(_vm.$nMapper.price(object.totalSumVAT, "")))])])])], 1) : _vm.customerView && field.jsonValue === 'phoneNumber' ? _c('div', [_c('v-row', [object.phoneNumbers[0] ? _c('p', {
        staticClass: "listing-item underlined",
        staticStyle: {
          "padding-right": "8px"
        },
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.selectPhone(object.phoneNumbers[0].phoneNumber);
          }
        }
      }, [_vm._v(" " + _vm._s(object.phoneNumbers[0].phoneNumber) + " ")]) : _vm._e(), object.phoneNumbers[0] ? _c('CopyButton', {
        attrs: {
          "toast-message": 'PhoneNumberCopied',
          "copy-text": object.phoneNumbers[0].phoneNumber
        }
      }) : _vm._e(), !object.phoneNumbers[0] ? _c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "width": "80px",
          "height": "20px"
        }
      }, [_vm._v(" - ")]) : _vm._e(), _c('v-menu', {
        attrs: {
          "close-on-content-click": false,
          "offset-y": "",
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref) {
            var on = _ref.on,
              attrs = _ref.attrs;
            return [_c('div', _vm._g(_vm._b({
              staticClass: "outputs-wrapper"
            }, 'div', attrs, false), on), [object.phoneNumbers[1] ? _c('p', {
              staticClass: "additional-info listing-item"
            }, [_vm._v(" " + _vm._s(_vm.numberOfPhones(object)) + " ")]) : _vm._e()])];
          }
        }], null, true)
      }, [_c('div', {
        staticClass: "outputs-modal"
      }, _vm._l(object.phoneNumbers.slice(1), function (object) {
        return _c('v-row', {
          key: object.id,
          staticClass: "output-row"
        }, [_c('p', {
          staticClass: "phone-number-text underlined",
          on: {
            "click": function ($event) {
              return _vm.selectPhone(object.phoneNumber);
            }
          }
        }, [_vm._v(" " + _vm._s(object.phoneNumber) + " ")]), _c('CopyButton', {
          attrs: {
            "toast-message": 'PhoneNumberCopied',
            "copy-text": object.phoneNumber
          }
        })], 1);
      }), 1)])], 1)], 1) : _vm.customerView && field.jsonValue === 'email' ? _c('div', [_c('v-row', [object.emails[0] ? _c('p', {
        staticClass: "listing-item underlined",
        staticStyle: {
          "padding-right": "8px"
        },
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.selectEmail(object.emails[0].email);
          }
        }
      }, [_vm._v(" " + _vm._s(object.emails[0].email) + " ")]) : _vm._e(), object.emails[0] ? _c('CopyButton', {
        attrs: {
          "toast-message": 'EmailCopied',
          "copy-text": object.emails[0].email
        }
      }) : _vm._e(), !object.emails[0] ? _c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "width": "80px",
          "height": "20px"
        }
      }, [_vm._v(" - ")]) : _vm._e(), _c('v-menu', {
        attrs: {
          "close-on-content-click": false,
          "nudge-width": 205,
          "offset-y": "",
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref2) {
            var on = _ref2.on,
              attrs = _ref2.attrs;
            return [_c('div', _vm._g(_vm._b({
              staticClass: "outputs-wrapper"
            }, 'div', attrs, false), on), [object.emails[1] ? _c('p', {
              staticClass: "additional-info listing-item"
            }, [_vm._v(" " + _vm._s(_vm.numberOfEmails(object)) + " ")]) : _vm._e()])];
          }
        }], null, true)
      }, [_c('div', {
        staticClass: "outputs-modal"
      }, _vm._l(object.emails.slice(1), function (object) {
        return _c('v-row', {
          key: object.id,
          staticClass: "output-row underlined",
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.selectEmail(object.email);
            }
          }
        }, [_vm._v(" " + _vm._s(object.email) + " "), _c('CopyButton', {
          attrs: {
            "toast-message": 'EmailCopied',
            "copy-text": object.email
          }
        })], 1);
      }), 1)])], 1)], 1) : _vm.customerView && field.jsonValue === 'organization' ? _c('div', [_c('v-row', [object.organizations[0] ? _c('p', {
        staticClass: "listing-item underlined",
        staticStyle: {
          "padding-right": "8px"
        }
      }, [_vm._v(" " + _vm._s(object.organizations[0].organization) + " ")]) : _vm._e(), object.organizations[0] ? _c('CopyButton', {
        attrs: {
          "toast-message": 'OrganizationDataCopied',
          "copy-text": _vm.selectOrganization(object.organizations[0])
        }
      }) : _vm._e(), !object.organizations[0] ? _c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "width": "80px",
          "height": "20px"
        }
      }, [_vm._v(" - ")]) : _vm._e(), _c('v-menu', {
        attrs: {
          "close-on-content-click": true,
          "nudge-width": 205,
          "offset-y": "",
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref3) {
            var on = _ref3.on,
              attrs = _ref3.attrs;
            return [_c('div', _vm._g(_vm._b({
              staticClass: "outputs-wrapper"
            }, 'div', attrs, false), on), [object.organizations[1] ? _c('p', {
              staticClass: "additional-info listing-item"
            }, [_vm._v(" " + _vm._s(_vm.numberOfOrganizations(object)) + " ")]) : _vm._e()])];
          }
        }], null, true)
      }, [_c('div', {
        staticClass: "outputs-modal"
      }, _vm._l(object.organizations.slice(1), function (org) {
        return _c('v-row', {
          key: org.id,
          staticClass: "output-row org-link",
          on: {
            "click": function ($event) {
              return _vm.openCustomer(object, org.reg_code, org);
            }
          }
        }, [_vm._v(" " + _vm._s(org.organization) + " "), _c('CopyButton', {
          attrs: {
            "toast-message": 'OrganizationDataCopied',
            "copy-text": _vm.selectOrganization(org)
          }
        })], 1);
      }), 1)])], 1)], 1) : field.jsonValue === 'invoiceType' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatInvoiceType(object["type"])) + " ")])])], 1) : field.jsonValue === 'invoiceProject' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap"
        }
      }, [_vm._v(" " + _vm._s(object["project"] && object["project"]["name"] ? object["project"]["name"] : "-") + " ")])])], 1) : field.jsonValue === 'broker' && !_vm.invoicesView ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap"
        }
      }, [_vm._v(" " + _vm._s(object["broker"] ? object["broker"]["name"] : object["creator"]["name"]) + " ")])])], 1) : field.jsonValue === 'broker' && _vm.invoicesView ? _c('div', [_c('div', {
        staticStyle: {
          "display": "flex",
          "justify-content": "space-between",
          "gap": "8px"
        }
      }, [_c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap",
          "width": "100%"
        }
      }, [_vm._v(" " + _vm._s(object["broker"] ? object["broker"]["name"] : object["creator"]["name"]) + " ")]), object['sharedBrokers'] && object['sharedBrokers'].length > 0 ? [_c('img', {
        attrs: {
          "src": require('@/assets/images/additional_users.svg'),
          "width": "20",
          "height": "20",
          "alt": ""
        }
      })] : _vm._e()], 2)]) : field.jsonValue === 'birthday' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatDateWithoutYear(object[field.jsonValue])) + " ")])])], 1) : field.jsonValue === 'tags' ? _c('div', [_c('v-row', {
        staticStyle: {
          "flex-wrap": "nowrap"
        }
      }, [object.tags && object.tags.propertyOwner['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#b5e7cf"
        }
      }, [_c('p', {
        staticClass: "tag-name"
      }, [_vm._v(_vm._s(_vm.$t("PO")))])]) : _vm._e(), object.tags && object.tags.landlord['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#b8d8ff"
        }
      }, [_c('p', {
        staticClass: "tag-name"
      }, [_vm._v(_vm._s(_vm.$t("L")))])]) : _vm._e(), object.tags && object.tags.buyer['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#fff2b3"
        }
      }, [_c('p', {
        staticClass: "tag-name"
      }, [_vm._v(_vm._s(_vm.$t("B")))])]) : _vm._e(), object.tags && object.tags.tenant['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#fcbae2"
        }
      }, [_c('p', {
        staticClass: "tag-name"
      }, [_vm._v(_vm._s(_vm.$t("T").toUpperCase()))])]) : _vm._e(), object.tags && object.tags.other['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#c3c4c6"
        }
      }, [_c('p', {
        staticClass: "tag-name"
      }, [_vm._v(_vm._s(_vm.$t("O")))])]) : _vm._e(), object.tags && object.tags.photographer && object.tags.photographer['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#16afb9"
        }
      }, [_c('p', {
        staticClass: "tag-name white-tag-text"
      }, [_vm._v(_vm._s(_vm.$t("PH")))])]) : _vm._e(), object.tags && object.tags.valuator && object.tags.valuator['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#aa086a"
        }
      }, [_c('p', {
        staticClass: "tag-name white-tag-text"
      }, [_vm._v(_vm._s(_vm.$t("VA")))])]) : _vm._e(), object.tags && object.tags.notary && object.tags.notary['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#4c8dd9"
        }
      }, [_c('p', {
        staticClass: "tag-name white-tag-text"
      }, [_vm._v(_vm._s(_vm.$t("NO")))])]) : _vm._e(), object.tags && object.tags.bank_representative && object.tags.bank_representative['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#b54616"
        }
      }, [_c('p', {
        staticClass: "tag-name white-tag-text"
      }, [_vm._v(_vm._s(_vm.$t("BA")))])]) : _vm._e(), object.tags && object.tags.otherInPartner && object.tags.otherInPartner['active'] ? _c('div', {
        staticClass: "tag-container-table",
        staticStyle: {
          "background-color": "#75787a"
        }
      }, [_c('p', {
        staticClass: "tag-name white-tag-text"
      }, [_vm._v(_vm._s(_vm.$t("O")))])]) : _vm._e(), !object.tags || !object.tags.propertyOwner['active'] && !object.tags.other['active'] && !object.tags.landlord['active'] && !object.tags.tenant['active'] && !object.tags.buyer['active'] && (!object.tags.photographer || !object.tags.photographer['active']) && (!object.tags.valuator || !object.tags.valuator['active']) && (!object.tags.notary || !object.tags.notary['active']) && (!object.tags.bank_representative || !object.tags.bank_representative['active']) && (!object.tags.otherInPartner || !object.tags.otherInPartner['active']) ? _c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" - ")]) : _vm._e()])], 1) : field.jsonValue === 'interests' ? _c('div', [_c('v-row', [object.interests ? _c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.numberOfInterests(object)) + " ")]) : _vm._e()])], 1) : field.jsonValue === 'projects' ? _c('div', [_c('v-row', [object.projectCounts ? _c('p', [_c('span', {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(_vm._s(object.projectCounts.active + object.projectCounts.archived) + " ")]), _c('span', [_vm._v("(" + _vm._s(object.projectCounts.active) + "/" + _vm._s(object.projectCounts.archived) + ")")])]) : _vm._e()])], 1) : field.jsonValue === 'files' ? _c('div', [object.files != '[]' && object.files != '' ? _c('v-row', [_c('img', {
        staticStyle: {
          "width": "15px",
          "height": "15px"
        },
        attrs: {
          "src": require("../../assets/images/attachmentBlack.svg"),
          "alt": ""
        }
      })]) : _vm._e()], 1) : field.jsonValue === 'firstname' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(object.firstname) + " ")])])], 1) : field.jsonValue === 'lastname' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(object.lastname) + " ")])])], 1) : field.jsonValue === 'accessLevel' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatRole(object)) + " ")])])], 1) : field.jsonValue === 'types' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item-no-ellipsis"
      }, [_vm._v(" " + _vm._s(_vm.formatUserType(object)) + " ")])])], 1) : field.jsonValue === 'group' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatGroupName(object)) + " ")])])], 1) : field.jsonValue === 'groups' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(object.groups.length) + " ")])])], 1) : field.jsonValue === 'LastLogin' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatLoginDate(object.LastLogin)) + " ")])])], 1) : field.jsonValue === 'userStatus' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(_vm._s(object.userStatus))])])], 1) : field.jsonValue === 'unpaid' && !_vm.invoicesView ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(_vm._s(object.unpaid))])])], 1) : field.jsonValue === 'transactionName' ? _c('div', [_c('v-row', {
        staticStyle: {
          "gap": "4px",
          "display": "flex",
          "flex-wrap": "nowrap",
          "align-content": "center",
          "justify-items": "start"
        }
      }, [_c('DealTypeContainer', {
        attrs: {
          "deal-type": object.transactionType
        }
      }), _c('p', {
        staticClass: "listing-item",
        staticStyle: {
          "overflow": "hidden",
          "max-width": "256px",
          "white-space": "nowrap",
          "text-overflow": "ellipsis"
        }
      }, [_vm._v(" " + _vm._s(object.transactionName) + " ")])], 1)], 1) : field.jsonValue === 'creator' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item hover-option"
      }, [_vm._v(" " + _vm._s(_vm.getOwner(object.access.users)) + " ")])])], 1) : field.jsonValue === 'dealValue' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.dealValue, "€")) + " ")])])], 1) : field.jsonValue === 'brokerageFee' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(object.brokerageFee, "€")) + " ")])])], 1) : field.jsonValue === 'transactionDate' || field.jsonValue === 'createdAt' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatDate(object[field.jsonValue])) + " ")])])], 1) : field.jsonValue === 'paymentDueDate' || field.jsonValue === 'invoiceDate' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(" " + _vm._s(_vm.formatDate(object[field.jsonValue])) + " ")])])], 1) : !_vm.invoicesView && field.jsonValue === 'status' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "statusLabel content-bold",
        class: {
          'paid-label': object.status === 'paid',
          'not-paid-label': object.status === 'not_paid'
        },
        attrs: {
          "id": 'statusLabel' + object._id
        },
        on: {
          "click": function ($event) {
            return _vm.openStatusDropdown(object);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.formatStatus(object.status)) + " ")])])], 1) : _vm.invoicesView && field.jsonValue === 'status' ? _c('div', [_c('StatusDropdown', {
        attrs: {
          "show-additional-info": true,
          "invoice": object
        },
        on: {
          "reloadInvoice": function ($event) {
            return _vm.$emit('reloadInvoices');
          }
        }
      })], 1) : field.jsonValue === 'listings' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_vm._v(_vm._s(object.listings))])])], 1) : field.jsonValue === 'activeListings' ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_c('a', {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(_vm._s(object.activeListings))]), _vm._v(" / "), _c('a', {
        attrs: {
          "name": 'activelistingstotal' + intex
        }
      }, [_vm._v(" " + _vm._s(object.activeListingsTotal))])])])], 1) : field.jsonValue === 'kvActive' && _vm.editableIndex !== index ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_c('a', {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(_vm._s(object.kvActive))]), _vm._v(" / "), _c('a', {
        attrs: {
          "name": 'kvtotal' + intex
        }
      }, [_vm._v(" " + _vm._s(object.kvTotal))])])])], 1) : field.jsonValue === 'kv24Active' && _vm.editableIndex !== index ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item",
        attrs: {
          "name": 'kv24Export' + intex
        }
      }, [_c('a', {
        staticStyle: {
          "font-weight": "bold"
        },
        attrs: {
          "name": 'kv24active' + intex
        }
      }, [_vm._v(_vm._s(object.kv24Active))]), _vm._v(" / "), _c('a', {
        attrs: {
          "name": 'kv24total' + intex
        }
      }, [_vm._v(_vm._s(object.kv24Total))])])])], 1) : field.jsonValue === 'city24Active' && _vm.editableIndex !== index ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item",
        attrs: {
          "name": 'city24Export' + intex
        }
      }, [_c('a', {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(_vm._s(object.city24Active))]), _vm._v(" / "), _c('a', {
        attrs: {
          "name": 'city24total' + intex
        }
      }, [_vm._v(_vm._s(object.city24Total))])])])], 1) : field.jsonValue === 'wwwActive' && _vm.editableIndex !== index ? _c('div', [_c('v-row', [_c('p', {
        staticClass: "listing-item"
      }, [_c('a', {
        staticStyle: {
          "font-weight": "bold"
        }
      }, [_vm._v(_vm._s(object.wwwActive))]), _vm._v(" / "), _c('a', {
        attrs: {
          "name": 'wwwtotal' + intex
        }
      }, [_vm._v(_vm._s(object.wwwTotal))])])])], 1) : object[field.jsonValue] === 'rented' && _vm.editableIndex !== index ? _c('div', {
        staticClass: "statusLabel rented"
      }, [_vm._v(" " + _vm._s(_vm.$t("Rented")) + " ")]) : object[field.jsonValue] === 'sold' && _vm.editableIndex !== index ? _c('div', {
        staticClass: "statusLabel rented"
      }, [_vm._v(" " + _vm._s(_vm.$t("Sold")) + " ")]) : object[field.jsonValue] === 'booked' && _vm.editableIndex !== index ? _c('div', {
        staticClass: "statusLabel booked"
      }, [_vm._v(" " + _vm._s(_vm.$t("Booked")) + " ")]) : object[field.jsonValue] === 'free' && _vm.editableIndex !== index ? _c('div', {
        staticClass: "statusLabel free"
      }, [_vm._v(" " + _vm._s(_vm.$t("Free")) + " ")]) : object[field.jsonValue] && _vm.editableIndex !== index && field.jsonValue !== 'client' && _vm.roundingDigits === undefined ? _c('p', {
        staticClass: "content-normal normal-field"
      }, [_vm._v(" " + _vm._s(object[field.jsonValue]) + " ")]) : object[field.jsonValue] && _vm.editableIndex !== index && field.jsonValue !== 'client' && _vm.roundingDigits !== undefined ? _c('p', {
        staticClass: "content-normal normal-field"
      }, [_c('span', [_c('v-tooltip', {
        attrs: {
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref4) {
            var on = _ref4.on,
              attrs = _ref4.attrs;
            return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(_vm.roundValue(object[field.jsonValue])) + " ")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v(_vm._s(_vm.formatTotal(object[field.jsonValue])))])])], 1)]) : field.jsonValue === 'client' && object[field.jsonValue] && _vm.editableIndex !== index ? _c('p', {
        staticClass: "content-normal normal-field"
      }, [_vm._v(" " + _vm._s(object.client.customerName) + " ")]) : _vm.editableIndex !== index ? _c('p', {
        staticClass: "content-normal normal-field"
      }, [_vm._v(" - ")]) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'client' ? _c('v-select', {
        attrs: {
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": "",
          "item-text": "customerName",
          "return-object": "",
          "items": _vm.customers
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = field.jsonValue;
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object[field.jsonValue],
          callback: function ($$v) {
            _vm.$set(object, field.jsonValue, $$v);
          },
          expression: "object[field.jsonValue]"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'status' ? _c('v-select', {
        attrs: {
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": "",
          "item-value": "value",
          "item-text": "name",
          "items": _vm.statuses,
          "label": _vm.$t('Status')
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = field.jsonValue;
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object[field.jsonValue],
          callback: function ($$v) {
            _vm.$set(object, field.jsonValue, $$v);
          },
          expression: "object[field.jsonValue]"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue !== 'client' && field.jsonValue !== 'status' && field.jsonValue !== 'kv24Active' && field.jsonValue !== 'kvActive' && field.jsonValue !== 'city24Active' && field.jsonValue !== 'wwwActive' ? _c('v-text-field', {
        attrs: {
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": ""
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = field.jsonValue;
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object[field.jsonValue],
          callback: function ($$v) {
            _vm.$set(object, field.jsonValue, $$v);
          },
          expression: "object[field.jsonValue]"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'kv24Active' ? _c('v-text-field', {
        attrs: {
          "type": "number",
          "hide-details": "",
          "height": "20",
          "name": 'kv24ExportField' + intex,
          "id": 'kv24ExportField',
          "outlined": "",
          "dense": ""
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = 'kv24Total';
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object['kv24Total'],
          callback: function ($$v) {
            _vm.$set(object, 'kv24Total', $$v);
          },
          expression: "object['kv24Total']"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'kvActive' ? _c('v-text-field', {
        attrs: {
          "type": "number",
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": ""
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = 'kvTotal';
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object['kvTotal'],
          callback: function ($$v) {
            _vm.$set(object, 'kvTotal', $$v);
          },
          expression: "object['kvTotal']"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'city24Active' ? _c('v-text-field', {
        attrs: {
          "type": "number",
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": ""
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = 'city24Total';
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object['city24Total'],
          callback: function ($$v) {
            _vm.$set(object, 'city24Total', $$v);
          },
          expression: "object['city24Total']"
        }
      }) : _vm._e(), _vm.editableIndex === index && field.jsonValue === 'wwwActive' ? _c('v-text-field', {
        attrs: {
          "type": "number",
          "hide-details": "",
          "height": "20",
          "outlined": "",
          "dense": ""
        },
        on: {
          "focusin": function ($event) {
            _vm.objectEdited = object;
            _vm.editedField = 'wwwTotal';
          },
          "focusout": _vm.saveEditedObject
        },
        model: {
          value: object['wwwTotal'],
          callback: function ($$v) {
            _vm.$set(object, 'wwwTotal', $$v);
          },
          expression: "object['wwwTotal']"
        }
      }) : _vm._e()], 1);
    }), 0);
  }), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.position
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }